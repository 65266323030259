var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4",
          attrs: {
            src: require("@/assets/images/pages/not-authorized.png"),
            alt: "graphic-not-authorized",
          },
        }),
        _c(
          "h1",
          {
            staticClass: "sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl",
            staticStyle: { color: "white" },
          },
          [_vm._v("Acesso restrito!")]
        ),
        _c("p", { staticClass: "sm:mx-0 mx-4 sm:mb-12 mb-8 text-danger" }, [
          _vm._v("Você não tem permissão para acessar a página solicitada."),
        ]),
        _c("vs-button", { attrs: { size: "large", to: "/" } }, [
          _vm._v("Voltar para o início"),
        ]),
        _c("vs-button", { attrs: { size: "large", to: "/logout" } }, [
          _vm._v("Desconectar"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }